/* eslint-disable max-len */
export const ADMINISTRATOR = 'ADMIN';
export const DEVELOPER = 'DEVELOPER';
export const TESTER = 'QA';
export const ENDUSER = 'ENDUSER';
export const FRONTM_DOMAIN = 'frontmai';
export const IM_BOT = 'im-bot';
export const FRONTM_BOT_ID = 'onboarding-bot';
export const BANNER_TYPE_INFO = 'infoMsg';
export const BANNER_TYPE_ERROR = 'errorMsg';
export const BANNER_TYPE_SUCCESS = 'successMsg';
export const COLOR_TYPE_INFO = '#19b373';
export const COLOR_TYPE_ERROR = '#fbbaba';
export const COLOR_TYPE_MIX = '#FEEFB3';
export const COLOR_TYPE_OFFLINE = '#FEEFB3';

export const COLOR_PRIMARY = '#638dff';

export const PRIMARY_COLOR_BUTTONS = '#638dff';
export const PRIMARY_COLOR_BUTTONS_HOVER = '#4c71d6';

export const PRIMARY_COLOR_BUTTONS_PIVOTEL = '#1780E7';
export const PRIMARY_COLOR_BUTTONS_PIVOTEL_HOVER = '#194E94';

export const FRONTM_WEB_BOT = {
  botId: FRONTM_BOT_ID,
  botName: 'FrontM Assistant',
  // logoUrl: "AuthenticationLogo.png",
  description: 'Onboarding bot for FrontM',
  logoUrl: 'AuthenticationLogo.png',
  userDomain: 'frontmai',
};
export const VOYAGER_VOICE_AUTH_CODE = 'gnsTestBots';
export const HELPER_MESSAGE = 'Please contact FrontM support. You have been logged in to default FrontM domain';

export const ACTIVATE_ENTERPRISE_BOTS_ERROR_CODES = [
  'Invalid code',
  'Code has been already used by the user',
  'Code could not be applied. The code has expired or the maximum limit has been reached',
];
export const ONBOARDINGBOT = 'onboarding-bot';

/* ====== Sign-up pages ========= */
export const VOYAGER_LANDING = '/voyagervoice';
export const VIKAND_LANDING = '/vikand';
export const VIKAND_DIRECT_LANDING = '/vikandconnect';
export const INTELL4DESK_LANDING = '/intelli4desks';
export const THURAYA_LANDING = '/thuraya';
export const THURAYA_DOMAIN = 'thuraya';
export const THURAYA_T2M_DOMAIN = "t2mtest";
export const THURAYA_STAGE_DOMAIN = "thurayaStage";
export const THURAYA_DEV_PHASE3_DOMAIN = "thuraya2";
export const BEACONASSIST_LANDING = '/beaconassist';
export const AAGEHEMPEL_LANDING = '/aagehempel';
export const ONSHIP_LANDING = '/onship';
export const THURAYA_T2M_LANDING = '/sattrackland';
export const ONSHIP_SIGNUP_LANDING = '/onship/signup';
export const THURAYA_T2M_SIGNUP_LANDING = '/sattrackland/signup';
export const STATION_SATCOM_LANDING = '/stationsatcom';
export const SIGMA_LANDING = '/sigma';
export const ONECARE_LANDING = '/onecare';
export const FRONTM_LANDING = '/signup';
export const FRONTM_LOGIN = '/login';
export const PIVOTEL_LANDING = '/pulsar';
export const FRONTM_TITLE = 'FrontM Platform';
export const ONSHIP_TITLE = 'onship';
export const THURAYA_T2M_TITLE = 'sattrackland';
export const THURAYA_TITLE = 'Thuraya';
export const VIKAND_TITLE = 'Vikand';
export const PIVOTEL_TITLE = 'Pulsar Community';
export const ONECARE_TITLE = 'OneCare Solutions';
export const STATION_SATCOM_TITLE = 'Station Satcom';
export const SIGMA_TITLE = 'Sigma';

export const LANDING_PATH_LIST = [
  VOYAGER_LANDING,
  VIKAND_LANDING,
  VIKAND_DIRECT_LANDING,
  INTELL4DESK_LANDING,
  THURAYA_LANDING,
  THURAYA_T2M_LANDING,
  STATION_SATCOM_LANDING,
  SIGMA_LANDING,
  ONECARE_LANDING,
  FRONTM_LANDING,
  FRONTM_LOGIN,
  PIVOTEL_LANDING,
];

export const CUSTOM_LANDING_PATH_LIST = [
  VOYAGER_LANDING,
  VIKAND_LANDING,
  PIVOTEL_LANDING,
  VIKAND_DIRECT_LANDING,
  INTELL4DESK_LANDING,
  THURAYA_LANDING,
  THURAYA_T2M_LANDING,
  STATION_SATCOM_LANDING,
  SIGMA_LANDING,
  ONECARE_LANDING,
  FRONTM_LANDING,
  FRONTM_LOGIN,
  ONSHIP_LANDING,
];

/* ======= Sign-in Header Text ==== */

export const VOYAGER_LOGIN_MAIN_HEADER_TEXT = 'Welcome!';
export const THURAYA_LOGIN_MAIN_HEADER_TEXT = 'Welcome!';
export const INTELL4DESK_LOGIN_MAIN_HEADER_TEXT = 'Welcome!';
export const VIKAND_LOGIN_MAIN_HEADER_TEXT = '';
export const PIVOTEL_LOGIN_MAIN_HEADER_TEXT = 'Welcome to Pulsar Community';
export const VOYAGER_LOGIN_SUB_HEADER_TEXT = 'Log in to Voyager Voice';
export const VIKAND_SIGNUP_SUB_HEADER_TEXT = 'Create New Account';
export const PIVOTEL_SIGNUP_SUB_HEADER_TEXT = 'Create New Account';
export const INTELL4DESK_SIGNUP_SUB_HEADER_TEXT = 'Create New Account';
export const VIKAND_LOGIN_SUB_HEADER_TEXT = 'Login to VIKAND Covid-19 Helpline';
export const VIKAND_DIRECT_LOGIN_SUB_HEADER_TEXT = 'Login to VIKAND Connect';
export const PIVOTEL_LOGIN_SUB_HEADER_TEXT = '';
export const INTELL4DESK_LOGIN_SUB_HEADER_TEXT = 'Login to Intelli4desks';
export const THURAYA_LOGIN_SUB_HEADER_TEXT = 'Log in to Thuraya SatTrack';
export const STATION_SATCOM_LOGIN_MAIN_HEADER_TEXT = 'Welcome!';
export const SIGMA_LOGIN_MAIN_HEADER_TEXT = 'Experience Seamless Connectivity';
export const STATION_SATCOM_LOGIN_SUB_HEADER_TEXT = 'Log in to Station Satcom';
export const STATION_VIKAND_LOGIN_SUB_HEADER_TEXT = 'Log in to Vikand';
export const ONSHIP_LOGIN_MAIN_HEADER_TEXT = 'Welcome!';
export const ONSHIP_LOGIN_SUB_HEADER_TEXT = '';
export const SIGMA_LOGIN_SUB_HEADER_TEXT = '';
export const ONECARE_LOGIN_MAIN_HEADER_TEXT = 'Welcome!';
export const ONECARE_LOGIN_SUB_HEADER_TEXT = 'Log in to OneCare Solutions';
export const THURAYA_T2M_LOGIN_MAIN_HEADER_TEXT = '';
export const THURAYA_T2M_LOGIN_SUB_HEADER_TEXT = '';
/* ======= Sign-up form Text ===== */

export const VOYAGER_MAIN_HEADER_TEXT = 'Get Low Cost, High Quality Calls between Shore and Ships';
export const VOYAGER_SUB_HEADER_TEXT = 'Save up to $7.50 a minute on L-band calls compared to\n standard rates and enjoy better call quality';

export const THURAYA_MAIN_HEADER_TEXT = 'Welcome to a more connected world';
export const THURAYA_SUB_HEADER_TEXT = '';
export const VIKAND_MAIN_HEADER_TEXT = 'Welcome to VIKAND Covid-19 Helpline where our staff of maritime seasoned medical professionals will assist you with your questions';
export const VIKAND_DIRECT_MAIN_HEADER_TEXT = 'Welcome to VIKAND';
export const INTELL4DESK_MAIN_HEADER_TEXT = 'Welcome to Intelli4desks';
export const VIKAND_SUB_HEADER_TEXT = 'Please register by entering your email address and the license key provided to you from your shipping/management company';
export const INTELL4DESK_SUB_HEADER_TEXT = 'Please register by entering your email address and the license key provided to you from your company';

export const STATION_SATCOM_MAIN_HEADER_TEXT = 'Create new VOIP calling account';
export const STATION_SATCOM_SUB_HEADER_TEXT = '';
export const ONSHIP_SUB_HEADER_TEXT = 'Register to the Onship Digital Marketplace';
export const THURAYA_T2M_MAIN_HEADER_TEXT = 'Welcome to a more connected world';
export const THURAYA_T2M_SUB_HEADER_TEXT = 'Create a new account';

export const POWERED_BY = 'Powered By';

/* =========== Login page ======== */

export const POWERED_BY_LOGO = '/img/frontm-header-logo@2x.png';
export const POWERED_BY_LOGO_T2M = '/img/poweredByFrontM.png';
export const POWERED_BY_B_N_W = '/img/poweredByBlackAndWhite.png';
export const POWERED_BY_WHITE_LABEL = '/img/poweredByFrontM_WhiteLabel.svg';
export const FRONTM_LOGO = '/img/frontm-header-logo@3x.png';
export const FRONTM_LOGO_ALT = 'FrontM Logo';
export const VOYAGER_LOGO = '/img/VoyagerVoice-HORZÔÇôlogoREV-master-CMYK.svg';
export const VOYAGER_LOGO_ALT = 'Voyager Logo';
// export const THURAYA_LOGO = "img/thuraya_logo.png";
export const THURAYA_LOGO = 'img/Thuraya-StarTrack-logo-new.png';
export const THURAYA_URL = 'https://www.thuraya.com/thuraya-marinestar';
export const THURAYA_ICON = 'img/app-icon.png';
export const THURAYA_LOGO_ALT = 'Thuraya Logo';
// export const THURAYA_T2M_LOGO = "img/Thuraya-StarTrack-logo-new.png";
export const THURAYA_T2M_URL = 'https://www.thuraya.com';
export const THURAYA_T2M_ICON = 'img/t2m_icon-200x150.jpg';
// export const THURAYA_T2M_LOGO_ALT = "Thuraya Logo";
export const VIKAND_LOGO = 'img/logos5.jpg';
export const PIVOTEL_LOGO = 'img/pivotel_logo_1.svg';
export const PIVOTEL_LOGO_ALT = 'img/pivotel_logo_2.png';

export const VIKAND_DIRECT_LOGO = 'img/VikandLogo.png';
export const INTELL4DESK_LOGO = 'img/Intelli4Desks_200x100.png';
export const VIKAND_LOGO_ALT = 'Vikand Logo';
export const INTELL4DESK_LOGO_ALT = 'Intelli4Desks Logo';
export const STATION_SATCOM_LOGO = 'img/StationSatcomNewLogo.png';
export const STATION_SATCOM_LOGO_ALT = 'Station Satcom Logo';
export const ONSHIP_LOGO = 'img/onship-logo-with-tagline2.svg';
export const ONSHIP_LOGO_ALT = 'Onship Logo';
export const SIGMA_LOGO = 'img/SIGMA_CORPORATE_LOGO.png';
export const SIGMA_LOGO_ALT = 'Sigma Logo';
export const THURAYA_T2M_LOGO = 'img/sattrack-2022.png';
export const THURAYA_T2M_LOGO_ALT = 'Thuraya Logo';
export const ONECARE_LOGO = 'img/OC_logoLandingPage.png';
export const ONECARE_LOGO_ALT = 'Onecare Logo';
export const FRONTM_VERSION_CHECK_LOGO = 'img/frontmLogoVersionCheckLogo.png';

export const VOYAGER_LOGIN_BACKGROUND = '#151C24';
export const THURAYA_LOGIN_BACKGROUND = '#ffff';
export const VIKAND_LOGIN_BACKGROUND = '#ffff';
export const INTELL4DESK_LOGIN_BACKGROUND = '#ffff';
export const STATION_SATCOM_LOGIN_BACKGROUND = '#ffff';
export const ONSHIP_LOGIN_BACKGROUND = '#fff';
export const SIGMA_LOGIN_BACKGROUND = '#fff';
export const PIVOTEL_LOGIN_BACKGROUND = '/img/pivotel_slider4.svg';
export const ONSHIP_SIGNUP_BACKGROUND = 'img/onship-loginNew2.png';
export const SIGMA_SIGNUP_BACKGROUND = 'img/SIGMA_SIGNUP_IMAGE.jpg';
export const ONSHIP_VERIFY_BACKGROUND = 'img/onboarding-onship-img@2x.png';
export const THURAYA_T2M_LOGIN_BACKGROUND = '#ffff';
export const THURAYA_T2M_SIGNUP_BACKGROUND = 'img/t2m_background_1800x2000.jpg';
export const THURAYA_T2M_VERIFY_BACKGROUND = 'img/t2m_background_2880x1580.jpg';
export const ONECARE_SIGNUP_BACKGROUND = 'img/OC_newLandingPage.jpg';
export const PIVOTEL_SIGNUP_BACKGROUND = '/img/pivotel_slider4.svg';

export const VOYAGER_LOGIN_MAIN_HEADER_TEXT_COLOR = '#fff';
export const VOYAGER_LOGIN_SUB_HEADER_TEXT_COLOR = '#fff';
export const THURAYA_LOGIN_MAIN_HEADER_TEXT_COLOR = '#4A4A4A';
export const THURAYA_LOGIN_SUB_HEADER_TEXT_COLOR = '#666666';
export const VIKAND_LOGIN_MAIN_HEADER_TEXT_COLOR = '#4A4A4A';
export const INTELL4DESK_LOGIN_MAIN_HEADER_TEXT_COLOR = '#4A4A4A';
export const VIKAND_LOGIN_SUB_HEADER_TEXT_COLOR = '#666666';
export const INTELL4DESK_LOGIN_SUB_HEADER_TEXT_COLOR = '#666666';
export const STATION_SATCOM_LOGIN_MAIN_HEADER_TEXT_COLOR = '#000000';
export const STATION_SATCOM_LOGIN_SUB_HEADER_TEXT_COLOR = '#000000';
export const ONECARE_MAIN_HEADER_TEXT_COLOR = '#4A4A4A';
export const ONECARE_SUB_HEADER_TEXT_COLOR = '#666666';
export const PIVOTEL_LOGIN_MAIN_HEADER_TEXT_COLOR = '#1A1B41B2';
export const PIVOTEL_LOGIN_SUB_HEADER_TEXT_COLOR = '#000000';

/* ========= Signup background ======== */

export const VOYAGER_SIGNUP_BACKGROUND = '/img/voyagervoice.jpg';
// export const THURAYA_SIGNUP_BACKGROUND = "/img/thuraya-onboarding-img@3x.png";
export const THURAYA_SIGNUP_BACKGROUND = '/img/new_thuraya_bg.jpg';
export const VIKAND_SIGNUP_BACKGROUND = '/img/Frontm-rhs-no-text.jpg';
export const VIKAND_DIRECT_SIGNUP_BACKGROUND = '/img/Vikand_Login_Page_3.png';
export const INTELL4DESK_SIGNUP_BACKGROUND = '/img/intelli4desk_bg.jpg';
export const STATION_SATCOM_SIGNUP_BACKGROUND = '/img/newStationSatcomBGM.png';
export const FRONTM_SIGNUP_BACKGROUND = '/img/green-background@2x.png';

/* ======= Login background ======== */

/* ======= Reset password ======== */
// export const THURAYA_RESET_BACKGROUND = "/img/thuraya-ship-bg.png";
export const THURAYA_RESET_BACKGROUND = '/img/new_thuraya_bg.jpg';
export const VIKAND_RESET_BACKGROUND = '/img/Frontm-rhs-no-text.jpg';
export const VIKAND_DIRECT_RESET_BACKGROUND = '/img/AccountResetPasswordBackgroundImage.png';
export const INTELL4DESK_RESET_BACKGROUND = '/img/intelli4desk_bg.jpg';
export const STATION_SATCOM_RESET_BACKGROUND = '/img/stationsatcom-onboarding-img.png';
export const ONSHIP_RESET_BACKGROUND = '/img/onboarding-onship-img@2x.png';
export const SIGMA_RESET_BACKGROUND = '/img/onboarding-onship-img@2x.png';
export const THURAYA_T2M_RESET_BACKGROUND = '/img/t2m_background_2880x1580.jpg';
export const ONECARE_RESET_BACKGROUND = '/img/OC_PasswordReset.jpg';
export const PIVOTEL_RESET_BACKGROUND = '/img/pivotel_slider4.svg';

// CLIENT PROD URLS

export const ONSHIP_PROD_URL = 'onship.app';
export const THURAYA_T2M_PROD_URL = 'sattrackland.thuraya.com';
export const VIKAND_PROD_URL = 'vikand.frontm.com';
export const VIKAND_HEALTH_PROD_URL = 'vikandtelehealth.com';
export const VIKAND_CONNECT_PROD_URL = 'vikandconnect.com';
export const STATIONSATCOM_PROD_URL = 'ssvideokonnect.com';
export const ONECARE_PROD_URL = 'onecare.frontm.com';
export const ONECARE_PROD_URL_NEW = 'onecaresolutions.app';
export const SIGMA_PROD_URL = 'sigmaxpoc.frontm.com';
export const PIVOTEL_PROD_URL = 'app.pulsarcommunity.com';
export const MARINE_INSIGHT_PROD_URL = 'marineinsight.onship.app';

export const SOCKET = { STREAM_QUEUE_MSG: null };

/* ========= STAGE URL ======== */
export const ONECARE_STAGE_URL = 'stage5.frontm.com';
export const ONSHIP_STAGE_URL = 'stage.frontm.com';
export const VIKAND_STAGE_URL = 'stage2.frontm.com';
export const THURAYA_STAGE_URL = 'stage3.frontm.com';
export const PIVOTEL_STAGE_URL = 'stage7.frontm.com';
export const STAGE_8_THURAYA_URL = "stage8.frontm.com";
export const ONSHIP_NEW_STAGE2_URL = "stage2.onship.app"
export const VIKAND_NEW_STAGE_URL = "vikandstage.onship.app"

/* ========= Anonymous User URL PROD ======== */
export const ONECARE_PROD_ANONYMOUS_USER_URL = '/messages/onecare/rLe4h47cmB1JN8eiLjzDkj';

/* ========= Anonymous User URL STAGE ======== */
export const ONECARE_STAGE_ANONYMOUS_USER_URL = '/messages/onecareuat/f3KaTtvcod27wJuAkG4nai';

/* ========= Anonymous User URL DEV ======== */
export const ONECARE_DEV_ANONYMOUS_USER_URL = '/messages/onecare/zG1pvXKCx47YXbiH3UTQv';

/* ===== USERDOMAINS ========= */

export const ONECARE = 'onecare';
export const ONECARE_STAGE = 'onecareuat';
export const VIKAND = 'vikand';
export const VIKAND_DIRECT = 'vikandconnect';
export const VIKAND_DIRECT_STAGE = 'vikanduat';
export const DOMAIN_STATIONSATCOM = 'ssvideokonnect';
export const STATIONSATCOM = 'stsatcom';
export const SIGMA = 'sigma';
export const DOMAIN_PIVOTEL = 'pulsar';

/* ====== Notifications push keys based on userdomains ============ */

// eslint-disable-next-line camelcase
export const onecare_pushkey = 'BH-83oxw5XHQNYcyZZoO1fk9F59nibmV5GTUzdKzd5gouMSFjaJleomrUuM5bjX13DQ9fnPfbj4n-B6TrvVCkKA';
// eslint-disable-next-line camelcase
export const vikand_direct_pushkey = 'BH_DNP8E7pFNf4a9YfkvoQ7Yjyq3b_nuyv646Q18qddlWPg0oiDcCqgRCyXNoGI4mYkZ-sy1pYl4ycmD5al8ggA';

export const DOMAIN_ONSHIP = 'onship';
export const DOMAIN_ONSHIP_STAGE = 'stage';

export const WINDOW_TITLE_DOMAINS = [
  'onship',
  'stsatcom',
  'nonsuchshipping1',
  'nonsuchshipping2',
  'nonsuchshipping3',
  'pulsarcommunity',
];
export const JITSI_DISABLED_DOMAIN_LIST = [];
export const PIVOTEL_DOMAINS = [
  'nonsuchshipping1',
  'nonsuchshipping2',
  'nonsuchshipping3',
  'pulsarcommunity',
];

// Slider Images
export const PIVOTEL_SLIDER_IMAGE_MAIN = '/img/pulsarSliderImage.svg';
export const PIVOTEL_SLIDER_1 = '/img/pivotel_slider2.svg';
export const PIVOTEL_SLIDER_2 = '/img/pivotel_slider1.svg';
export const PIVOTEL_SLIDER_3 = '/img/pivotel_slider3.svg';
export const PIVOTEL_SLIDER_4 = '/img/pivotel_homeSlider4.svg';
export const PIVOTEL_LOGIN_IMAGE = '/img/pivotel_LoginScreenImage.svg';
// new slide navButton Images
export const SLIDE_LEFT_NAV_BUTTON = '/img/leftNavNewSlide.svg';
export const SLIDE_RIGHT_NAV_BUTTON = '/img/rightNavNewSlide.svg';

//call and video cam new icons
export const VIDEO_CAM_ICON = "/img/videoCallNewIcon.svg"
export const CALL_BUTTON_ICON = "/img/callNewIcon.svg"

export const NEW_VIDEO_CAM_ICON = "/img/videoCam.svg"
export const NEW_CALL_BUTTON_ICON = "/img/callButtn.svg"
export const FAVORITE_STAR_UNSELECTED="/img/Fav-star.svg"
export const FAVORITE_STAR_SELECTED="/img/Fav-star-selected.svg"
export const FAVORITE_STAR_DISABLED="/img/Fav-star-disabled.svg"


// export const CHAT_ICON = "public/img/ChhatIcon.svg"
export const MESSAGE_ICON = "/img/Group.svg"
export const SATELITE_ICON = "/img/sateliteIcon.svg"
export const MOBILE_ICON = "/img/mobileicon.svg"
export const PHONE_ICON = "/img/phoneIcon.svg"
export const COMMON_CALL_ICON = "/img/commonCallIcon.svg"
export const INFORMATION_ICON = "/img/InfoIcon.svg"
export const MAIL_ICON = "/img/mailIcon.svg"
export const  BLUE_MAIL_ICON = "/img/bluemailiconn.svg"
export const  SHORE_BADGE_ICON = "/img/shoreBadge.svg"
export const  SINGLE_CHAT_ICON = "/img/singleChatIcon.svg"
export const  SHIP_BADGE_ICON = "/img/shipBadge.svg"
export const  SHIP_OPERATOR_ICON = "/img/shipOperatorIcon.svg"
export const SALOON_ICON = "/img/saloonIcon.svg"
export const SELECT_CONTACT_IMAGE = "/img/selectContactImage.svg"
export const ADD_NEW_CONTACT = "/img/Addnewcontact.svg"
export const PLUS_ICON = "/img/plusIcon.svg"
export const NEW_PHONE_ICON = "/img/newPhoneIcon.svg"
export const DIALPAD_ICON = "/img/dialpadIcon.svg"
export const COMPANY_ICON = "/img/companyIcon.svg"
export const FLAG_ICON = "/img/flagIcon.svg"
export const HOME_ICON = "/img/homeIcon.svg"
export const INSTA_ICON = "/img/instaIcon.svg"
export const LINKEDIN_ICON = "/img/linkedinIcon.svg"
export const FB_ICON = "/img/facebook_icon.svg"
export const PENCIL_ICON = "/img/pencilIcon.svg"
export const TWITTER_ICON = "/img/twitterIcon.png"
export const SHIPP_ICON = "/img/shipIIcon.svg"
export const CARD_CLASSSOCIETY_ICON = "/img/cardClassificationSocietyIcon.svg"
export const CARD_DECK_ICON = "/img/cardDeckIcon.svg"
export const CARD_ENGINE_ICON = "/img/cardEngineIcon.svg"
export const CARD_PORT_AUTHORITY_ICON = "/img/cardPortAuthorityIcon.svg"
export const CARD_SALOON_ICON = "/img/cardSaloonIcon.svg"
export const CARD_SHIP_ICON = "/img/CardShipIcon.svg"
export const CELL_ICON = "/img/cellIcon.svg"
export const CONTACT_CALL_ICON = "/img/contactCallIcon.svg"
export const SETTING_ICON = "/img/shipIIcon.svg"
export const CLASSIFICATION_SOCIETY_ICON = "/img/classificationSocietyIcon.svg"
export const PORT_AUTHORITY_ICON = "/img/portAuthorityIcon.svg"
export const CONTACT_PHONE_ICON = "/img/contactPhoneIcon.svg"
export const NO_CONTACTS_FOUND = "/img/no-contacts-illstration.svg"
export const SEARCH_ICON = "/img/MagnifyingGlassIcon.svg"
export const PERSONAL_CONTACT_ICON = "/img/personalContactIcon.svg"
export const UNCATEGORISED_ICON = "/img/uncategorisedIcon.svg"
export const EDIT_ICON = "/img/editIcon.svg"
export const PENCIL_EDIT_ICON = "/img/PencilEditIcon.svg"
export const TICK_ICON = "/img/tickIcon.svg"
export const CHEVRON_DOWN = "/img/chevron_down.svg"
export const CHEVRON_UP = "/img/chevron_up.svg"
export const CLOSE_ICON = "/img/close_icon_round.svg"


//settings UI
export const SETTINGS_PROFILE_ICON = "img/settings_profile.svg"
export const CHEVRON_RIGHT = "img/menu-chevron-right@3x.png"
export const BALANCE_ADD_ICON = "img/balanceAddIcon.svg"
export const CHEVRON_RIGHT_ACTIVE = "img/rightChevronActive.svg"
export const SETTINGS_LOGOUT_ICON = "img/logoutSettingsIcon.svg"
export const SETTINGS_DELETE_ACCOUNT_ICON = "img/deleteAccountIcon.svg"


//new modal ui
export const MODAL_CLOSE_ICON = "img/modalNewUICloseIcon.svg"
export const TWO_FA_SHIELD_ICON = "img/securityCheck2FA_Shield.svg"
export const DUMMY_QR = "/img/dummyQR.svg"

//contact form icons
export const ADD_NEW_CONTACT_SEARCH_ACTIVE = 'img/searchIconActive.svg'
export const ADD_NEW_CONTACT_SEARCH_INACTIVE = 'img/searchIconInactive.svg'

export const ADD_NEW_CONTACT_NEW_ICON_ACTIVE = 'img/addNewContactActive.svg'
export const ADD_NEW_CONTACT_NEW_ICON_INACTIVE = 'img/addNewContactInactive.png'

export const ADD_NEW_CONTACT_EMAIL_ICON_ACTIVE = 'img/emailIconActive.svg'
export const ADD_NEW_CONTACT_EMAIL_ICON_INACTIVE = 'img/emailIconInactive.svg'

export const NOTIFICATON_BELL_ICON = 'img/notificationBell.svg'

// User Profile page
export const ABOUT_ME_CHAR_LIMIT = 250;